.block-comment-wraper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 700px;
}

.block-avatar {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
    margin-top: 5px;
}

.block-avatar-wrapper {
    height: 30px;
    width: 30px;
    margin-right: 20px;
    border-radius: 50%;
    background-color: #828282;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.block-avatar-name {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    color: #FFF;
}

.block-comment-body {
    display: flex;
    flex-direction: column;
    width: 650px;
}

.block-commment-header {
    display: flex;
    flex-direction: row;
    width: 650px;
    margin-bottom: 15px;
}

.block-username {
    margin: 0;
    margin-right: 15px;
    color: #000;
    font-weight: 500;
    font-size: 16px;
}

.block-date {
    margin: 0;
    font-weight: 400;
    color: #828282;
    font-size: 14px;
}

.block-comment-text {
    width: 650px;
    margin: 0;
    margin-bottom: 15px;
    color: #828282;
    font-size: 14px;
    line-height: 25px;
}

.block-comment-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 650px;
    margin-bottom: 20px;
}

.block-link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline;
    margin: 0;
    padding: 0;
    color: #bf0a31;
    font-size: 14px;
}

.block-dot {
    color: #80808063;
    margin: 0 10px;
    font-size: 7px;
}

.block-counter {
    margin: 0;
    margin-right: 5px;
    color: #000;
    font-size: 14px;
}

.block-replys-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
}

.block-reply-header {
    display: flex;
    flex-direction: row;
    width: 620px;
    margin-bottom: 15px;
}

.block-reply-text {
    width: 620px;
    margin: 0;
    margin-bottom: 15px;
    color: #828282;
    font-size: 14px;
    line-height: 25px;
}

.block-reply-username {
    color: #000;
}

.block-reply-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 620px;
    margin-bottom: 20px;
}

.block-social-wrapper {
    display: flex;
    flex-direction: row;
    /* justify-content: */
}

@media (max-width: 768px) {
    .block-comment-wraper {
        width: 300px;
    }

    .block-comment-body {
        width: 250px;
    }

    .block-commment-header {
        width: 250px;
    }

    .block-comment-text {
        width: 250px;
    }

    .block-comment-footer {
        flex-direction: column;
        width: 250px;
        align-items: baseline;
    }

    .block-reply-header {
        width: 220px;
    }

    .block-reply-text {
        width: 220px;
    }

    .block-reply-footer {
        flex-direction: column;
        width: 220px;
        align-items: baseline;
    }
}