.contact .container {
    width: 100%;
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    position: relative;
    padding-bottom: 50px;
}
.contact .container .row {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}
.contact .container .row .column {
    width: 45%;
}
.contact .container .row .column p.info {
    margin-bottom: 20px;
    font-size: 22px;
    text-align: center;
}
.contact .container .row .column p.info2 {
    margin-bottom: 20px;
    font-size: 18px;
    text-align: left;
    line-height: 30px;
}
.contact .container .row .column #contactForm {
    width: 640px;
    height: 1010px;
}
@media screen and (max-width: 762px) {
    .contact .container .row {
        flex-direction: column-reverse;
        padding: 0;
    }
    .contact .container .row .column {
        width: 100%;
    }
    .contact .container .row .column #contactForm {
        width: 100%;
        margin: 0 auto;
    }
}
.contact .container .contactImage {
    width: 1000px;
    height: 100%;
}
@media screen and (max-width: 762px) {
    .contact .container .contactImage {
        width: 100%;
    }
    .contact .container .row .column {
        padding: 0 20px;
    }
}
