.privacyLink {
  word-break: break-word;
}
.privacy .container {
  width: 100%;
  max-width: 1170px;
  margin: auto;
  padding: 0 20px;
}
.privacy .container h2 {
  font-weight: bold;
  text-decoration: underline;
  font-size: 24px;
  text-align: center;
}
.privacy .container .underline {
  text-decoration: underline;
}
.privacy .container .last-updated {
  font-size: 11px;
  font-style: italic;
}
.privacy .container p {
  margin: 30px 0;
}
.privacy .container li {
  list-style: decimal;
  margin-left: 15px;
}
.privacy .container li p {
  margin-left: -15px;
}
.privacy .container li .list-item-header {
  display: inline-block;
  padding-left: 50px;
  color: #000;
}
