.settings-button-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
}

.settings-save-button {
    align-self: flex-end;
    margin-right: 20px !important;
}

.settings-message {
    font-size: 14px;
    text-align: end;
    margin-right: 20px;
}

.success {
    color: green;
}

.error {
    color: red
}