.comment-wrapper {
    display: flex;
    flex-direction: column;
    width: 700px;
    margin-bottom: 40px;
}

.comment {
    margin-left: -50px;
}

.reply {
    margin-left: -75px;
}

.comment-title {
    font-weight: bold;
    line-height: 20px;
    margin: 0;
    margin-bottom: 20px;
}

.comment-question {
    font-size: 14px;
    line-height: 25px;
    margin: 0;
    margin-bottom: 20px;
    color: #828282;
}

.comment-counter {
    line-height: 20px;
    margin: 0;
    margin-bottom: 10px;
    margin-top: 10px;
    color: #828282;
    font-size: 13px;
}

.comment-emoji-wrapper {
    position: absolute;
    margin-top: -365px;
    margin-left: 400px;
    z-index: 100;
}

.comment-buttons-wrapper {
    display: flex;
    justify-content: flex-end;
}

.comment-form-button {
    width: 200px;
    margin-left: 10px !important;
}

.comment-info-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
}

.comment-info {
    display: flex;
}

.comment-avatar {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
    margin-top: 5px;
}

.comment-avatar-wrapper {
    height: 30px;
    width: 30px;
    margin-right: 20px;
    border-radius: 50%;
    background-color: #828282;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.comment-avatar-name {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    color: #FFF;
}

.comment-user-name {
    margin: 0;
    color: #000;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
    line-height: 20px;
}

.comment-user-date {
    margin: 0;
    color: #828282;
    line-height: 20px;
    font-size: 16px;

}

@media (max-width: 768px) {
    .comment-wrapper {
        width: 300px;
    }

    .comment-buttons-wrapper {
        justify-content: space-between;
    }

    .comment-form-button {
        width: 140px;
        margin-left: 0 !important;
    }

    .comment-emoji-wrapper {
        position: absolute;
        width: 300px;
        margin-left: 0;
    }
}