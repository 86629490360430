footer {
    z-index: 1100;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 60px;
    margin-top: 50px;
    justify-content: center;
    height: 190px;
}
footer .links {
    color: #ffffff;
}
footer .links ul {
    text-align: center;
}
footer .links ul li {
    display: inline-block;
    list-style: none;
    padding: 0 5px;
}
footer .links ul li a {
    color: #cfefff;
    font-weight: 300;
    font-size: 16px;
    text-decoration: none;
    letter-spacing: 1px;
    line-height: 22px;
}
footer .links ul li a:hover {
    text-decoration: underline;
}

@media screen and (max-width: 762px) {

    .links {
        max-width: 70%;
        margin: 0 auto;
    }

}