.privacy {

  .container {
    width: 1170px;
    margin: auto;

    h2 {
      font-weight: bold;
      text-decoration: underline;
      font-size: 24px;
      text-align: center;
    }

    .underline {
      text-decoration: underline;
    }

    .last-updated {
      font-size: 11px;
      font-style: italic;
    }

    p {
      margin: 30px 0;
    }

    li {
      list-style: decimal;
      margin-left: 15px;

      p {
        margin-left: -15px;
      }

      .list-item-header {
        display: inline-block;
        padding-left: 50px;
        color: #000;
      }

    }
  }
}