.debate {
    background-color: #FFFFFF;
}
.debate form {
    margin-bottom: 100px;
}
@media screen and (max-width: 768px) {
    .debate form {
        margin-bottom: 50px;
    }
}
.debate .container {
    background-color: #FFFFFF;
    width: 100%;
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    position: relative;
}
.debate .container h1 {
    font-size: 55px;
    font-weight: 700;
    line-height: 68.75px;
    margin-bottom: 30px;
    max-width: 555px;
}
@media screen and (max-width: 762px) {
    .debate .container h1 {
        /* width: 90%; */
        padding: 0 10px;
        font-size: 42px;
        line-height: 52.5px;
    }
}
.debate .container .row {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
}
.debate .container .row .column {
    width: 50%;
}
@media screen and (max-width: 762px) {
    .debate .container {
        width: 100%;
        max-width: 100%;
    }
    .debate .container .row {
        flex-direction: column;
        margin-top: -20px;
    }
    .debate .container .row #battleImg {
        max-width: 100%;
        margin-top: 70px;
    }
    .debate .container .row .column {
        width: 100%;
    }
}
@media screen and (max-width: 762px) {
    .debate .container {
        margin-top: 30px;
    }
}
.debate .info {
    line-height: 1.6em;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 30px;
}
@media screen and (max-width: 1000px) {
    .debate .info {
        padding-left: 40px;
    }
}
@media screen and (max-width: 762px) {
    .debate .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
    }
}
.debate .info p {
    margin-bottom: 20px;
}
.debate a.button {
    border: 5px solid transparent;
    transition: 0.5s;
    display: block;
    max-width: 300px;
    margin: 0 auto;
    padding: 15px 0px;
    border-radius: 50px;
    background-color: #940E15;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 1px;
    color: #FFFFFF;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
}
@media screen and (max-width: 762px) {
    .debate a.button {
        width: 100%;
        margin-top: 40px;
    }
}
.debate a.button:hover {
    background-color: #FFFFFF;
    border: 5px solid #940E15;
    color: #434443;
}
.debate #battleImg {
    width: 555px;
}
.debate #cardsContainer {
    margin: 150px 0;
    padding: 80px 0;
}
.debate #cardsContainer h3 {
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 40px;
}
.debate #cardsContainer .row {
    margin: 0 auto;
    display: flex;
    max-width: 1180px;
    flex-direction: row;
    justify-content: space-between;
}
.debate #cardsContainer .card {
    background-color: #FFFFFF;
    width: 375px;
    padding: 60px 40px 40px;
    border-radius: 18px;
    align-self: flex-end;
    position: relative;
    box-sizing: border-box;
}
@media screen and (max-width: 1000px) {
    .debate #cardsContainer .card {
        margin: 0 10px;
    }
}
.debate #cardsContainer .card .cardNumber {
    position: absolute;
    top: -28px;
    left: 35px;
    background-color: #034D73;
    border-radius: 50px;
    width: 56px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    font-size: 28px;
    font-weight: 400;
    color: #FFFFFF;
}
.debate #cardsContainer .card h4 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #434443;
    font-family: 'Cardo', serif;
}
.debate #cardsContainer .card p {
    font-size: 18px;
    line-height: 28.8px;
    font-weight: 400;
}
@media screen and (max-width: 762px) {
    .debate #cardsContainer {
        margin-bottom: 75px;
    }
    .debate #cardsContainer h3 {
        font-size: 30px;
        margin-bottom: 40px;
    }
    .debate #cardsContainer .row {
        flex-direction: column;
        width: 100%;
        max-width: 100%;
    }
    .debate #cardsContainer .row .card {
        width: 90%;
        margin: 30px auto;
    }
}
.debate #formContainer {
    width: 100%;
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    position: relative;
}
.debate #formContainer .row {
    margin: 0 auto;
    display: flex;
    max-width: 1180px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}
.debate #formContainer .row .column {
    width: 45%;
}
.debate #formContainer .row .column #signupForm {
    width: 640px;
    height: 1350px;
}
.debate #formContainer .row .column h2 {
    margin-left: 60px;
    font-size: 32px;
    text-align: left;
}
.debate #formContainer .row .column p.infoText {
    margin-top: 30px;
    margin-left: 60px;
    text-align: left;
    font-size: 18px;
}
.debate #formContainer .row .column img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
}
@media screen and (max-width: 762px) {
    .debate #formContainer .row {
        flex-direction: column-reverse;
        width: 100%;
        max-width: 100%;
    }
    .debate #formContainer .row .column {
        width: 100%;
    }
    .debate #formContainer .row .column #signupForm {
        width: 90%;
        margin: 0 auto;
    }
    .debate #formContainer .row .column h2,
    .debate #formContainer .row .column p {
        margin-left: 30px;
    }
    .debateIllustration {
        padding: 0 20px;
    }
}
.debate #appContainer {
    width: 100%;
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    position: relative;
}
.debate #appContainer .row {
    margin: 0 auto;
    display: flex;
    max-width: 1180px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.debate #appContainer .row .column {
    width: 45%;
    text-align: center;
}
.debate #appContainer .row .column h2 {
    margin-left: 60px;
    font-size: 32px;
    text-align: left;
}
.debate #appContainer .row .column p {
    margin-top: 30px;
    margin-left: 60px;
    text-align: left;
    font-size: 18px;
}
.debate #appContainer .row .column .links {
    padding-top: 7%;
}
.debate #appContainer .row .column .links a {
    width: 30%;
    display: inline-block;
}
.debate #appContainer .row .column .links a:first-child {
    margin-right: 1.4%;
}
.debate #appContainer .row .column .links a img {
    max-width: 100%;
}
.debate #appContainer .row .column .appPreviewBG {
    width: 90%;
    height: 765px;
    position: relative;
    background-size: cover;
    background-image: url(https://ocov.us/images/app-screen-bg.png);
    font-family: 'Rubik', sans-serif;
    text-align: left;
}
.debate #appContainer .row .column .appPreviewBG .previewContainer {
    width: 88%;
    height: 425px;
    overflow-y: scroll;
    position: absolute;
    top: 196px;
    padding-left: 36px;
    /* margin-top: 160px; */
}
.debate #appContainer .row .column .appPreviewBG .previewPollHeroImage {
    width: 63%;
    border-radius: 8px;
    margin: 0 0 0 33%;
}
.debate #appContainer .row .column .appPreviewBG .previewPollName {
    margin: 20px 0 0 33%;
    width: 65%;
    font-size: 15px;
    line-height: 20px;
}
.debate #appContainer .row .column .appPreviewBG .buttonRow {
    display: flex;
    justify-content: flex-start;
    margin: 1% 0 3% 33%;
}
.debate #appContainer .row .column .appPreviewBG .buttonRow .dot {
    color: #ccc;
    margin: 0 4px;
    font-size: 18px;
    line-height: 11px;
}
.debate #appContainer .row .column .appPreviewBG .buttonRow .fakeLink {
    color: #940E15;
    font-size: 11px;
}
.debate #appContainer .row .column .appPreviewBG .previewPollSmall {
    width: 65%;
    display: flex;
    margin: 0 0 0 32%;
    padding: 5% 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}
.debate #appContainer .row .column .appPreviewBG .previewPollSmall .previewPollSmallImage {
    width: 23%;
    border-radius: 8px;
}
.debate #appContainer .row .column .appPreviewBG .previewPollSmall .previewPollSmallRightCol {
    margin: 0 0 0 10%;
}
.debate #appContainer .row .column .appPreviewBG .previewPollSmall .previewPollSmallRightCol .previewPollSmallName {
    font-size: 12px;
}
.debate #appContainer .row .column .appPreviewBG .previewPollSmall .previewPollSmallRightCol .buttonRowSmall {
    display: flex;
    justify-content: flex-start;
    margin-top: 12px;
}
.debate #appContainer .row .column .appPreviewBG .previewPollSmall .previewPollSmallRightCol .buttonRowSmall .dot {
    color: #ccc;
    margin: 0 4px;
    font-size: 18px;
    line-height: 11px;
}
.debate #appContainer .row .column .appPreviewBG .previewPollSmall .previewPollSmallRightCol .buttonRowSmall .fakeLink {
    color: #940E15;
    font-size: 11px;
}
.loading {
    margin-left: 265px;
    margin-top: 360px;
}
@media screen and (max-width: 762px) {
    .debate #appContainer .row .column .appPreviewBG {
        margin-left: -20%;
        width: 120%;
    }
}
.debate #appContainer .row .column .phoneImg {
    width: 90%;
}
@media screen and (max-width: 762px) {
    .debate #appContainer .row .column .phoneImg {
        margin-left: -10%;
    }
}
@media screen and (max-width: 762px) {
    .debate #appContainer .row {
        flex-direction: column;
        width: 100%;
        max-width: 100%;
    }
    .debate #appContainer .row .column {
        width: 100%;
    }
    .debate #appContainer .row .column h2,
    .debate #appContainer .row .column p {
        margin-left: 30px;
    }
    .debate #appContainer .row .column .links {
        margin-bottom: 50px;
    }
    .debate #appContainer .row .column .links a {
        width: 40%;
    }
    .debate #appContainer .row .column .phoneImg {
        margin-left: -10%;
    }
}
