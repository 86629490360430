@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap');
* {
    box-sizing: border-box;
}

body {
    min-height: 100vh;
    width: 100%;
    font-family: Inter, sans-serif;
}

a {
    cursor: pointer;
    outline: none;
}
strong {
    font-weight: bold;
}

a.underline {
    color: rgb(27, 134, 241);
    text-decoration: none;
    overflow: hidden;
    display: inline-block;
    position: absolute;
    padding: 0 0.2em;
}
a.underline::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1em;
    background-color: rgba(191,10,49,1);
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
}

a.underline::after {
    opacity: 1;
    transform: translate3d(-100%, 0, 0);
}

a.underline:hover::after,
a.underline:focus::after{
    transform: translate3d(0, 0, 0);
}

h1 {
    font-weight: bold;
    font-size: 3.4375rem;
}
p {
    font-size: 1.375rem;
    line-height: 2.1875rem;
}
img {
    max-width: 100%;
}
.mt-20 {
    margin-top: 20px;
}
.mt-40 {
    margin-top: 40px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mb-40 {
    margin-bottom: 40px;
}
.pt-20 {
    padding-top: 20px;
}
.pt-40 {
    padding-top: 40px;
}
.pb-20 {
    padding-bottom: 20px;
}
.pb-40 {
    padding-bottom: 40px;
}
.text-center {
    text-align: center;
}
.white {
    color: #fff;
}
.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.container {
    width: 100%;
    max-width: 1170px;
    justify-self: center;
    margin: 0 auto;
}
.flex {
    display: flex;
}
.flex-column {
    flex-direction: column;
}
.flex-column-reverse {
    flex-direction: column-reverse;
}
.justify-space-between {
    justify-content: space-between;
}

.button {
    display: inline-block;
    color: #ffffff;
    background-color: #1e93f7;
    text-decoration: none;
    text-align: center;
    border-radius: 5px;
    font-size: 14px;
    line-height: 18px;
    padding: 15px 24px;
    letter-spacing: 1px;
    min-width: 100px;
}

:root {
    font-family: "Rubik", sans-serif;

    --primary-color: #0073ae;
    --primary-warning: #ff0000;
    --primary-light-gray: #858585;

    --background-primary: #0073ae;
    --background-white: #ffffff;
}

.auth-wrapper {
    min-height: 100vh;
    background-size: cover;
    background-color: #000;
    background-image: url(assets/images/bg.jpg);
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-logo-top {
    width: 300px;
    height: 150px;
    position: absolute;
    top: 0;
    right: 0;
}

.auth-logo {
    width: 200px;
    height: 100px;
    top: 0;
    right: 0;
}

.auth-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 300px;
    max-width: 360px;
    padding: 40px 50px;
    background-color: var(--background-white);
    border-radius: 10px;
    position: relative;
    margin: 30px 0;
}

.auth-title {
    padding-top: 10px;
    font-weight: 600;
}

.auth-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.auth-form-input {
    width: 360px;
    margin: 15px 0 0 !important;
}

.auth-form-button {
    margin-top: 20px !important;
    width: 100%;
}

.auth-link-wrapper {
    display: flex;
    padding-top: 30px;
}

.auth-link {
    text-decoration: none;
    color: var(--primary-color);
}

.error-text {
    color: var(--primary-warning) !important;
}

.bg-blue{
    background-color: #193251;
}

.bg-red{
    background-color: #941815 !important;
}

@media (max-width: 468px) {

    .auth-wrapper {
        background-image: none;
        background-color: #47515e;
    }

    .auth-content-wrapper {
        padding: 30px 10px;
        width: 280px;
    }

    .auth-title {
        font-size: 20px;
    }

    .auth-form-input {
        width: 280px;
    }
}
