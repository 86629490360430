article.archive-article {
    display: flex;
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid #ccc;
}

article.archive-article title {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 20px;
}

article.archive-article title a {
    color: #333;
    text-decoration: none;
}

article.archive-article title a:hover {
    text-decoration: underline;
}

article.archive-article p {
    line-height: 20px;
    font-size: 14px;
}

article.archive-article .right-col img {
    max-width: 315px;
}

article.archive-article:last-child {
    border-bottom: none;
}

@media screen and (max-width: 1250px) {
    /*h1 {*/
    /*    font-weight: bold;*/
    /*    font-size: 1.4375rem;*/
    /*    padding-left: 40px;*/
    /*}*/
    .archive-article .right-col {
        padding-right: 40px;
        display: flex;
        align-items: center;
    }

    article.archive-article p {
        text-align: justify;
    }
}

@media screen and (max-width: 768px) {
    .archive-article {
        flex-direction: column-reverse;
    }
    h1 {
        font-weight: bold;
        font-size: 1.4375rem;
        padding-left: 40px;
    }

    article.archive-article .right-col {
        padding-right: 0;
    }

    article.archive-article .right-col img {
        max-width: 100%;
        padding-right: 40px;
        padding-left: 40px;
    }
    article.container.flex-reverse a {
        word-break: break-all;
    }
}

@media screen and (max-width: 468px) {
    h1 {
        padding-left: 0;
    }
    .right-col img {
        max-width: unset;
        width: 100%;
        margin-bottom: 20px;
    }
}