.demographics-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.demographics-title {
    font-size: 18px;
    margin: 0;
    text-align: center;
}

.demographics-block-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 762px;
}

.demographics-form-wrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.demographics-save-form-wrapper {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

.demographics-save-button {
    margin-top: 20px !important;
    width: 360px;
    align-self: center;
}

.demographics-save-row {
    display: flex;
    justify-content: space-around;
}

.demographics-form-input {
    width: 360px;
    margin: 25px 0 0 !important;
}

.demographics-form-button {
    margin-top: 20px !important;
    width: 360px;
}

.message {
    font-size: 14px;
    text-align: center;
}

.success {
    color: green;
}

.error {
    color: red
}

@media (max-width: 762px) {
    .demographics-save-row {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .demographics-form-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .demographics-form-input {
        width: 300px;
        margin: 15px 0 0 !important;
    }

    .demographics-form-button {
        margin-top: 20px !important;
        width: 300px;
    }

    .demographics-save-button {
        margin-top: 20px !important;
        width: 300px;
        align-self: center;
    }
}