article.container {
    margin-top: 30px;
}
.container.flex-reverse {
    display: flex;
    flex-direction: row;
}
.left-col {
    width: 100%;
    max-width: 850px;
    padding-right: 40px;
}
.right-col img {
    max-width: 500px;
}
title {
    display: block;
    font-size: 34px;
    margin-bottom: 30px;
    line-height: 45.9px;
}
p {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 30px;
}
.article-footer {
    margin-top: 40px;
    width: 100%;
    text-align: center;
}
.app-links {
    width: 100%;
    max-width: 430px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 10px;
}
.app-links a {
    display: block;
    max-width: 200px;
    height: 80px;
}
svg {
    width: 100%;
}

ul {
    padding-left: 20px;
}

ul li {
    list-style: circle;
    padding-left: 20px;
}

.article-comments-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.article-comment-title {
    width: 700px;
    margin: 0;
    margin-bottom: 20px;
    margin-top: 40px;
    font-size: 24px;
    font-weight: bold;
}

@media screen and (max-width: 1250px) {
    h1 {
        font-weight: bold;
        font-size: 1.4375rem;
        padding-left: 40px;
    }
    .container.flex-reverse {
        flex-direction: column-reverse;
    }
    title {
        margin-top: 40px;
    }
    .left-col {
        padding-left: 40px;
        max-width: 100%;
    }
    .right-col {
        text-align: center;
    }

    p {
        text-align: justify;
    }
}

@media screen and (max-width: 600px){
    .app-links {
        width: 90%;
        max-width: 430px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto 10px;
    }
    .app-links a {
        display: block;
        max-width: 150px;
        height: 60px;
    }
    .article-footer p {
        font-size: 14px;
        padding: 0 40px;
    }
    .right-col img {
        padding: 0 40px;
    }
    .article-comment-title {
        width: 300px;
    }
}

@media screen and (max-width: 468px) {
    h1 {
        padding-left: 10px;
        text-align: center;
    }
}