.about .container {
    width: 100%;
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    position: relative;
    padding-bottom: 50px;
}

.aboutTitle {
    font-size: 34px;
    font-weight: 500;
    line-height: 68.75px;
    margin-bottom: 30px;
    max-width: 555px;
}

.about .container p {
    font-size: 18px;
    line-height: 27px;
}

.about .container .row {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.about .container .row .column {
    width: 45%;
}

.about .container .aboutImage {
    width: 555px;
    height: 100%;
}

.container .row .column .p {
    color: red;
}

.home-about-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0;
    padding: 30px 0;
}

.home-title-wrapper {
    display: flex;
    flex-direction: column;
}

.home-title {
    font-size: 34px;
    font-weight: 500;
    max-width: 555px;
    color: white;
    margin-bottom: 10px;
}

.home-description {
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    color: white;
    margin: 0;
    max-width: 95%;
}

.home-top-logo {
    width: 63%;
    height: 150px;
    object-fit: cover;
}

.home-create {
    text-align: center;
    margin-bottom: 25px;
}

.home-who-block-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 30px 20px;
    margin-top: 20px;
}

.home-who-img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.home-who-img {
    width: 75%;
}

.home-who-title {
    font-size: 34px;
    font-weight: 500;
    margin-bottom: 20px;
}

.home-who-subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 10px;
}

.home-who-content-wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.home-blocks-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0;
}

.home-block-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
}

.home-who-block-title {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 15px;
}

.home-who-block-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 10px;
    text-align: center;
}

.home-statistic-blocks-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 70px 20px;
}

.home-statistic-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 30%;
}

.home-statistic-title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 10px;
    color: white;
}

.home-statistic-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: white;
    text-align: center;
    margin: 0;
}

.home-info-block-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    margin-bottom: -50px;
}

.home-info-text {
    color: white;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    margin: 0;
}

.svg-icons {
    transform: scale(3.2);
    margin: 20px 0;
    color: #941815;
}

@media screen and (max-width: 1250px) {
    .home-description {
        padding-left: 40px;
    }

    .about .container .aboutImage {
        padding-right: 40px;
    }

    .home-who-title {
        padding: 0;
    }

    .home-info-block-wrapper {
        padding: 50px 20px;
    }
}

@media screen and (max-width: 1000px) {
    .home-title {
        padding-left: 20px !important;
    }

    .home-description {
        padding: 0 20px;
    }
}

@media screen and (max-width: 762px) {
    .home-about-wrapper {
        flex-direction: column;
    }

    .home-title {
        text-align: start;
        padding-top: 20px;
    }

    .home-description {
        max-width: 100%;
        margin-bottom: 10px;
    }

    .home-top-logo {
        width: 100%;
        height: 100%;
        padding: 0 20px !important;
    }

    .about .container .row {
        flex-direction: column-reverse;
    }

    .about .container .row .column {
        width: 100%;
    }

    .aboutDescription {
        padding: 0 20px;
    }

    .about .container .aboutImage {
        width: 100%;
        padding: 0 20px;
    }

    .home-who-block-wrapper {
        flex-direction: column;
    }

    .home-who-img-wrapper {
        width: 100%;
    }

    .home-who-content-wrapper {
        width: 100%;
    }

    .home-who-title {
        margin-top: 20px;
    }

    .home-statistic-blocks-wrapper {
        flex-direction: column;
        align-items: center;
        padding: 70px 20px;
    }

    .home-statistic-wrapper {
        margin: 20px 0;
        width: 100%;
    }

    .container .home-blocks-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .home-block-wrapper {
        margin: 15px 0;
        width: 100%;
    }
}
