.yesno-wrapper {
    padding: 20px;
}

.yesno-title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.yesno-buttons-wrapper {
    display: flex;
    justify-content: space-between;
}

.yesno-form-button {
    width: 49%;
}

@media (max-width: 762px) {
    .yesno-buttons-wrapper {
        flex-direction: column;
    }

    .yesno-form-button {
        margin-bottom: 10px;
        width: 100%;
    }
}