.select-value-wrapper {
    padding: 20px;
}

.select-value-title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
}

.select-value-select-wrapper {
    width: 100%;
}

.select-value-button {
    width: 100%;
    margin-top: 20px !important;
}