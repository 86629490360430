.header-logo {
    width: 160px;
    height: 41px;
}
.header-bg {
    width: 100%;
    height: 90px;
    display: flex;
    align-items: flex-end;
    background-size: cover;
    background-color: #001e5f;
    color: #ffffff;
    margin-bottom: 30px;
    background-repeat: no-repeat;
    background-position: 0 -170px;
    align-content: baseline;
}
h1 {
    font-family: 'Cardo', serif;
}
nav {
    padding-bottom: 0;
}
nav ul li {
    list-style: none;
    margin: 0 10px 0;
    display: inline-block;
}

nav li a {
    color: #001e5f;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;

    padding: 5px 0;
    transition: .5s;
    font-family: 'Cardo', serif !important;
}

nav li a.active {
    color: rgba(191, 10, 49, 1);
    /*border-bottom: 3px solid rgba(191, 10, 49, 1);*/
    padding: 2px 0;
    transition: .3s;
}
nav li a.active:hover {
    border-bottom: 3px solid rgba(191, 10, 49, 1);
}
nav li a:hover {
    border-bottom: 3px solid #001e5f;
    padding: 2px 0;
    transition: .3s;
}

.subscribed {
    color: #333333;
}
.subscribe input {
    font-size: 16px;
    margin-top: 4px;
    display: inline-block;
    margin-right: 4px;
}
.container-header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 1170px;
    margin: 0 auto;
}
.nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    max-width: 1170px;
    margin: 0 auto;
}

.container-header h1 {
    font-size: 40px;
    line-height: 40px;
}

.white-box {
    background-color: #fff;
    border-radius: 50px;
    max-width: 360px;
    display: flex;
    justify-content: space-between;
}

.subscribe {
    display: flex;
    align-items: center;
}

.close {
    display: flex;
    align-self: flex-end;
    margin-top: 8px;
    font-weight: bold;
    color: #333333;
    font-size: 24px;
    margin-right: 10px;
}

@media screen and (max-width: 1250px) {
    nav ul {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    li:last-child {
        padding-right: 20px;
    }
    .header-logo {
        margin-left: 40px;
    }
}

@media screen and (max-width: 1107px) {
    nav li a {
        font-size: 14px;
    }
}

@media screen and (max-width: 1000px) {
    nav {
        display: none
    }
    .header-logo {
        margin: 15px 0;
        margin-left: 0;
        width: 100%;
        height: auto;
        max-width: 160px;
    }
    .subscribe {
        padding-top: 0;
    }
    .container-header h1 {
        font-size: 35px;
        line-height: 40px;
        padding-left: 20px;
    }
    .header-login .button {
        font-size: 12px;
        margin-right: 15px;
    }
}

@media (max-width: 768px) {
    .header-bg {
        margin-bottom: 20px;
    }
    .container-header h1 {
        font-size: 26px;
    }
}

@media (max-width: 468px) {
    .container-header {
        justify-content: center;
    }
    .subscribe {
        padding-left: 10px;
        padding-right: 0;
    }
    .submit-button {
        margin-left: 10px;
    }
    .header-login .button {
        font-size: 10px;
        margin-right: 5px;
        padding: 10px 5px;
    }
}