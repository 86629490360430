.share-wrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.share-title {
    font-size: 16px;
    line-height: 20px;
}

.share-icons-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.share-button-wrapper {
    margin-right: 10px;
    margin-bottom: 10px;
}

.share-copy-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.share-form-input {
    width: 360px;
}

.share-copy-button {
    width: 160px;
    border-radius: 20px !important;
}

.share-copy-message {
    margin: 0;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: #bf0a31;
}

@media (max-width: 762px) {
    .share-copy-wrapper {
        flex-direction: column;
    }

    .share-form-input {
        width: 260px;
    }

    .share-copy-button {
        margin-top: 20px !important;
        width: 260px;
    }
}