.about .container {
    width: 100%;
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    position: relative;
    padding-bottom: 50px;
}
.aboutTitle {
    font-size: 34px;
    font-weight: 500;
    line-height: 69px;
    margin-bottom: 30px;
    max-width: 555px;
}
.about .container p {
    font-size: 18px;
    line-height: 27px;
}
.about .container .row {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.about .container .row .column {
    width: 45%;
}
.about .container .aboutImage {
    width: 100%;
}
.container .row .column .p {
    color: red;
}
.becomeAMemberContainer {
    margin-top: 20px;
    width: 100%;
    text-align: center;
}

.aboutList {
    font-size: 18px;
}

.aboutList li {
    margin-bottom: 10px;
    margin-right: 20px;
    line-height: 27px;
}

.faq {
    margin-top: 40px;
}

@media screen and (max-width: 1250px) {
    .aboutDescription {
        padding-left: 40px;
    }
    .about .container .aboutImage {
        padding-right: 40px;
    }
}
@media screen and (max-width: 762px) {
    .aboutTitle {
        padding-left: 20px !important;
        text-align: start;
        padding-top: 20px;
    }
    .about .container .row {
        flex-direction: column-reverse;
    }
    .about .container .row .column {
        width: 100%;
    }
    .aboutDescription {
        padding: 0 20px;
    }
    .aboutList {
        margin-left: 20px;
    }
    .aboutList li {

    }
    .faq {
        margin: 60px 20px 0;
    }
    .about .container .aboutImage {
        width: 100%;
        padding: 0 20px;
    }
}
