.polls-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.polls-main-poll-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    max-width: 700px;
}

.polls-main-image {
    object-fit: cover;
    height: 300px;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 10px;
}

.polls-description {
    margin: 0;
    line-height: 25px;
    margin-bottom: 10px;
    font-weight: bold;
    color: #000;
}

.polls-links-wrapper {
    display: flex;
    align-items: center;
}

.polls-cursor {
    cursor: pointer;
}

.polls-dot {
    color: #80808063;
    margin: 0 10px;
}

.polls-small-image {
    object-fit: cover;
    border-radius: 5px;
    height: 70px;
    width: 70px;
    margin-right: 30px;
}

.polls-poll-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 700px;
}

.polls-divider {
    margin-bottom: 20px;
}

.polls-small-poll-wrapper {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.polls-small-description-wrapper {
    display: flex;
    flex-direction: column;
}

.polls-link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline;
    margin: 0;
    padding: 0;
    color: #bf0a31;
}

.polls-no-results-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}

.polls-not-found-icon {
    font-size: 30px;
}

.polls-no-search-main-text {
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    margin-top: 20px;
    color: #cfcfcf;
}

.polls-no-search-sub-text {
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    margin-top: 20px;
    color: #000;
}


@media (max-width: 468px) {
    .polls-main-image {
        height: 200px;
    }

    .polls-no-search-main-text {
        font-size: 16px;
        text-align: center;
    }

    .polls-no-search-sub-text {
        font-size: 16px;
        text-align: center;
    }
}