.subscripe-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    padding: 30px;
}

.subscripe-title {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
}

.subscripe-input {
    width: 100%;
}

.subscripe-submit-button {
    margin-top: 20px;
}

.subscribe-error-text {
    font-size: 12px;
    margin: 0;
    padding-top: 5px;
    color: red;
    line-height: 20px;
    text-align: center;
}