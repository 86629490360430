.signin-error-text {
    color: #FF0000;
    text-align: center;
    padding-top: 20px;
}

.auth-wrapper {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 64px);
    max-width: 600px;
    margin: 0 10px;
    min-height: 0;
    background-image: none;
}

.auth-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 300px;
    max-width: 360px;
    padding: 0 50px;
    background-color: var(--background-white);
    border-radius: 10px;
    position: relative;
    margin: 30px 0;
}

.auth-logo {
    height: auto;
}

.auth-form-input {
    width: 320px;
}

.auth-title {
    padding: 0;
    margin: 30px 0 20px;
    font-weight: bold;
    font-size: 22px;
}

.auth-link-wrapper {
    display: flex;
    padding: 20px 0;
}

.message {
    margin-top: 10px;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 0;
}

.success {
    color: green;
}

.error {
    color: red
}

@media (max-width: 768px) {
    .auth-wrapper {
        max-width: 300px;
    }

    .auth-content-wrapper {
        padding: 0 30px;
        width: 280px;
    }

    .auth-form-input {
        width: 240px;
    }
}
