.hero-bg {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    color: #ffffff;
    margin-bottom: 150px;
    background-repeat: no-repeat;
    background-position: center center;
}
.cols {
    display: flex;
    flex-direction: row;
}
.half:first-child {
    box-sizing: border-box;
    width: 50%;
}
.half:first-child {
    padding-right: 40px;
}
#blog-title {
    box-sizing: border-box;
}
#blog-title p {
    max-width: 520px;
    font-size: 1.5rem;
    line-height: 2.1rem;
    margin-bottom: 50px;
}
#blog-title h1 {
    font-weight: bold;
    font-size: 3.6rem;
    margin-bottom: 20px
}

.debate-header {
    font-weight: 500;
    font-size: 2.0rem;
    line-height: 2.6rem;
    margin-bottom: 30px;
}
.debate-header a {
    text-decoration: none;
    color: #030303;
}
.whiteBox {
    background-color: #fff;
    padding: 10px 10px 10px 20px;
    border-radius: 50px;
    max-width: 360px;
    display: flex;
    justify-content: space-between;
}
.featured-article {
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid #001e5f;
}
.featured-article p a {
    text-decoration: none;
    color: #303030;
}
.featured-article:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}
img.featured-blog-image {
    width: 100%;
    max-width: 500px;
    height: auto;
}

@media screen and (max-width: 1250px) {
    .cols, .hero-bg {
        padding: 40px;
    }
}

@media screen and (max-width: 600px){
    .hero-bg {
        margin-bottom: 75px;
        padding: 0;
    }
    .cols {
        flex-direction: column;
        padding: 0;
    }
    .half {
        width: 100% !important;
        padding: 40px;
    }
    .half:last-child {
        padding: 0 40px 40px;
    }
    #blog-title {
        padding: 40px;
    }
    #blog-title p {
        max-width: 100%;
    }
}