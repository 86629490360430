.poll-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.poll-icons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 700px;
}

.poll-image-wrapper {
    height: 500px;
    width: 700px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.poll-name-text {
    color: #000;
    max-width: 700px;
    font-size: 18px;
    line-height: 30px;
    font-weight: bold;
    margin: 0;
    padding: 0 20px 20px 20px;
}

.poll-copyrigth-text {
    color: white;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    margin: 0;
    padding: 0 0 20px 20px;
}

.poll-answer-button-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
}

.poll-answer-button {
    width: 500px;
    padding: 5px 0 5px 30px;
    border-radius: 5px;
    border: 1px solid darkgray;
    cursor: pointer;
    z-index: 2;
    position: relative;
}

.poll-answer-button-selected {
    border-left-color: transparent;
}

.vote {
    margin-bottom: 10px;
}

.selected {
    width: 10px;
    background-color: #bf0a31;
    z-index: 3;
    position: absolute;
    flex: 1 1;
    left: 0;
    top: -1px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    height: calc(100% + 2px);
}

.poll-answer-button-text {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    padding-right: 20px;
}

.poll-answer-percentage-text {
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    color: lightslategray;
    padding-top: 5px;
}

.poll-vote-button {
    margin-top: 20px !important;
    width: 500px;
    border-radius: 15px;
}

.background {
    position: absolute;
    background-color: #e8e5e5;
    opacity: 0.9;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    z-index: -1;
}

.poll-comments-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.poll-comment-title {
    width: 700px;
    margin: 0;
    margin-bottom: 20px;
    margin-top: 40px;
    font-size: 24px;
    font-weight: bold;
}

.poll-comment-wraper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 700px;
}

.poll-avatar {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
    margin-top: 5px;
}

.poll-avatar-wrapper {
    height: 30px;
    width: 30px;
    margin-right: 20px;
    border-radius: 50%;
    background-color: #828282;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.poll-avatar-name {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    color: #FFF;
}

.poll-comment-body {
    display: flex;
    flex-direction: column;
    width: 650px;
}

.poll-commment-header {
    display: flex;
    flex-direction: row;
    width: 650px;
    margin-bottom: 15px;
}

.poll-username {
    margin: 0;
    margin-right: 15px;
    color: #000;
    font-weight: 500;
    font-size: 16px;
}

.poll-date {
    margin: 0;
    font-weight: 400;
    color: #828282;
    font-size: 14px;
}

.poll-comment-text {
    width: 650px;
    margin: 0;
    margin-bottom: 15px;
    color: #828282;
    font-size: 14px;
    line-height: 25px;
}

.poll-comment-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 650px;
    margin-bottom: 20px;
}

.poll-link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline;
    margin: 0;
    padding: 0;
    color: #bf0a31;
    font-size: 14px;
}

.poll-dot {
    color: #80808063;
    margin: 0 10px;
    font-size: 7px;
}

.poll-counter {
    margin: 0;
    margin-right: 5px;
    color: #000;
    font-size: 14px;
}

.poll-replys-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
}

.poll-reply-header {
    display: flex;
    flex-direction: row;
    width: 620px;
    margin-bottom: 15px;
}

.poll-reply-text {
    width: 620px;
    margin: 0;
    margin-bottom: 15px;
    color: #828282;
    font-size: 14px;
    line-height: 25px;
}

.poll-reply-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 620px;
    margin-bottom: 20px;
}

.poll-social-wrapper {
    display: flex;
    flex-direction: row;
    /* justify-content: */
}

@media (max-width: 768px) {
    .poll-image-wrapper {
        height: 350px;
        width: 100%;
        border-radius: 0;
    }

    .poll-answer-button {
        width: 300px;
    }

    .poll-vote-button {
        width: 300px;
    }

    .poll-icons-wrapper {
        padding: 0 20px;
    }

    .poll-comment-title {
        width: 300px;
    }

    .poll-comment-wraper {
        width: 300px;
    }

    .poll-comment-body {
        width: 250px;
    }

    .poll-commment-header {
        width: 250px;
    }

    .poll-comment-text {
        width: 250px;
    }

    .poll-comment-footer {
        flex-direction: column;
        width: 250px;
        align-items: baseline;
    }

    .poll-reply-header {
        width: 220px;
    }

    .poll-reply-text {
        width: 220px;
    }

    .poll-reply-footer {
        flex-direction: column;
        width: 220px;
        align-items: baseline;
    }
}