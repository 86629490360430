.profile-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.profile-photo-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.profile-photo-block {
    display: flex;
    align-items: center;
}

.profile-title {
    font-size: 18px;
    margin: 0;
}

.profile-change-button {
    width: 72px;
    padding: 0;
    margin-top: 10px;
}

.profile-block-wrapper {
    margin-bottom: 20px;
    width: 360px;
}

.profile-form-input {
    width: 360px;
    margin: 15px 0 0 !important;
}

.profile-form-button {
    margin-top: 20px !important;
    width: 360px;
}

.message {
    font-size: 14px;
    text-align: center;
}

.success {
    color: green;
}

.error {
    color: red
}

@media (max-width: 468px) {
    .profile-form-input {
        width: 300px;
    }

    .profile-form-button {
        width: 300px;
    }

    .profile-block-wrapper {
        width: 300px;
    }
}